/* eslint-disable @typescript-eslint/no-unused-expressions */
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'app/core/auth/auth.service';
import { DocumentService } from 'app/core/services/common/document.service';
import { RfxService } from 'app/core/services/rfx-service/rfx/rfx.service';
import { UtilService } from 'app/core/services/util.service';
import { RFX_FORM_STATUS } from 'app/enums/common-enum/common-enum';
import { DROPDOWN_TYPE } from 'app/enums/form-enum/form-enum';
import { ProposalLevelStatusEnum } from 'app/enums/rfx-enum/proposal-level-status-enum';
import { RfxApprovalStageEnum } from 'app/enums/rfx-enum/rfx-approval-stage-enum.enum';
import { AwardCriteria } from 'app/enums/rfx-enum/rfx-award-enum';
import { SUPPLIER_STATUS } from 'app/enums/rfx-enum/rfx.enum';
import { ViewerTypeEnum } from 'app/enums/viewer-type.enum';
import { curentUserInfo } from 'app/helper/shared-function';
import { UserAccessRoles, UserAccessRolesLevelOne } from 'app/interfaces/User.interface';
import { BillOfQuantity } from 'app/interfaces/bill-of-quantity';
import { ApprovalRouteLevel } from 'app/interfaces/rfx-interface/rfx-common.interface';
import { SupportingDocument } from 'app/interfaces/rfx-interface/rfx-form.interface';
import {
    DownloadPayload,
    OverAllCostSummary,
    SourcingProposal,
    SourcingPropsalFormAction,
    SupplierResponse
} from 'app/interfaces/sourcing-progress-event/sourcing-evalution.interface';
import { User } from 'app/interfaces/users';
import { RfxEscalatePopupComponent } from 'app/modules/rfx/rfx-escalate-popup/rfx-escalate-popup.component';
import * as moment from 'moment';
import { lastValueFrom } from 'rxjs';

@Component({
    selector: 'app-sourcing-proposal',
    templateUrl: './sourcing-proposal.component.html',
    styleUrls: ['./sourcing-proposal.component.scss'],
})
export class SourcingProposalComponent implements OnInit, OnDestroy {
    @Input() isFromPR: boolean = false;
    @Input() rfxId: number;
    @Input() rfxSequenceId: number;
    @Input() accessObj: UserAccessRoles;
    @Input() userAccessObject: UserAccessRolesLevelOne;
    @Output() refreshEvent: EventEmitter<any> = new EventEmitter<any>();

    awardCriteria = new FormControl('');
    awardCriteriaEnum = AwardCriteria;

    sourcingEvalution: SourcingProposal;
    supplierResponses: Array<SupplierResponse>;
    parentSupplierResponseIds: Array<number>;
    showDraftAndApprovalBtn = false;

    form: FormGroup;
    displayedColumns: string[] = ['filename', 'createdDate', 'actions'];
    deletedDocumentIds: Array<number> = [];

    dropdownTypeEnum = DROPDOWN_TYPE;

    quality = [
        { key: 'Yes', value: 'YES' },
        { key: 'No', value: 'NO' },
    ];

    evalution = [
        { key: 'Ok', value: 'OK' },
        { key: 'Ng', value: 'NG' },
    ];

    sourcingPropsalFormAction = SourcingPropsalFormAction;

    approvalForm: FormGroup;
    showApprovalForm = false;
    showRemarkError = false;
    proposalLevelStatusEnum = ProposalLevelStatusEnum;
    rfxFormStatusEnum = RFX_FORM_STATUS;
    viewerTypeEnum = ViewerTypeEnum;

    loggedUser: User;
    supplierStatusEnum = SUPPLIER_STATUS;
    proposalApprovalLevels: ApprovalRouteLevel[];

    overAllCostSummary = [];
    supplierWiseSummaryMap = new Map<string, OverAllCostSummary>();
    manualLocalStorageKey = 'manualItems';

    showOriginalRfqCols = false;
    showEscalateBtn = false;

    constructor(
        private fb: FormBuilder,
        private rfxService: RfxService,
        private activatedRoute: ActivatedRoute,
        private utils: UtilService,
        private authService: AuthService,
        private router: Router,
        public dialog: MatDialog,
        private documentService: DocumentService
    ) { }


    get proposalSelectionCriteria(): FormArray {
        return this.form.get('proposalSelectionCriteria').get('suppliers') as FormArray;
    }

    get proposalsEvaluationStatus(): FormArray {
        return this.form.get('proposalsEvaluationStatus') as FormArray;
    }

    get awardedSupllierFormArray(): FormArray {
        return this.form.get('awardedSupllier') as FormArray;
    }

    ngOnInit(): void {
        this.loggedUser = curentUserInfo();
        this.initForm();
        if (this.isFromPR) {
            this.form.controls.id.setValue(Number(this.rfxId));
            this.rfxSourcingProposalByRfxId();
        } else {
            this.activatedRoute.queryParams.subscribe((params) => {
                if (this.authService.accessToken) {
                    this.rfxId = params.id;
                    this.form.controls.id.setValue(Number(this.rfxId));
                    this.rfxSourcingProposalByRfxId();
                } else {
                    this.router.navigate(['/sign-in'], {
                        state: {
                            route: 'rfx/sourcing-event-progress/',
                            params: params,
                        },
                    });
                }
            });
        }
    }

    ngOnDestroy(): void {
        if (this.refreshEvent) {
            this.refreshEvent.complete();
        }
    }

    initForm(): void {
        this.form = this.fb.group({
            id: [null],
            // evaluationHistory: this.fb.array([]),
            proposalsEvaluationStatus: this.fb.array([]),
            proposalSelectionCriteria: this.selectionCriteriaFormGrp(),
            // comment: [''],
            recommendation: ['', [Validators.required]],
            paymentSchedule: [''],
            // awardCriteria: [this.awardCriteriaEnum.LOWEST_ITEMIZED_PRICE],
            awardCriteria: [''],
            sourcingProposalDoc: [[]],
            awardedSupllier: this.fb.array([]),
        });

        this.approvalForm = this.fb.group({
            remark: [''],
        });
    }

    // selectionCriteria formgroup
    selectionCriteriaFormGrp(vendorCode?: string): FormGroup {
        return this.fb.group({
            qualitySpecificationTarget: [''],
            leadTimeTarget: [''],
            costTarget: [''],
            warrantyTarget: [''],
            suppliers: this.fb.array([]),
        });
    }

    // selectionCriteria supplier formgroup
    supplierFormGroup(obj?: any): FormGroup {
        return this.fb.group({
            id: [null],
            name: obj?.supplier?.companyName,
            vendorCode: obj.vendorCode,
            qualitySpecification: [obj?.status === SUPPLIER_STATUS.QUALIFIED ? 'YES' : ''],
            leadTime: [''],
            cost: [''],
            warranty: [''],
            status: [obj?.status]
        });
    }

    tempsupplierEvaluationStatusForm(obj: any): FormGroup {
        return this.fb.group({
            id: [null],
            name: obj?.supplier?.companyName,
            vendorCode: obj?.supplier?.vendorCode,
            supplierEvaluation: [obj?.status === SUPPLIER_STATUS.QUALIFIED ? 'OK' : ''],
            remark: [''],
            taxExemtionNo: [''],
            status: [obj?.status]
        });
    }

    rfxSourcingProposalByRfxId(): void {
        this.rfxService.rfxSourcingProposalByRfxId(this.rfxId).subscribe(
            (res) => {
                this.sourcingEvalution = { ...res };
                this.setData();
            },
            (error) => { }
        );
    }

    async setData(): Promise<void> {
        if (this.sourcingEvalution) {

            this.setProposalDetails();

            this.proposalsEvaluationStatus.clear();
            this.proposalSelectionCriteria.clear();

            let tempSupplierResponses = this.sourcingEvalution.supplierResponses;
            this.parentSupplierResponseIds = [];

            let parentSourcingProposal = null;
            if (this.sourcingEvalution?.isNegotiation && this.sourcingEvalution.originalEventId) {
                parentSourcingProposal = await lastValueFrom(this.rfxService.rfxSourcingProposalByRfxId(this.sourcingEvalution.originalEventId));
                if (parentSourcingProposal?.supplierResponses?.length > 0) {
                    const parentResponses = parentSourcingProposal?.supplierResponses;
                    const newResVendors: string[] = tempSupplierResponses.map(res => res.vendorCode);
                    const parentSupplierRes = parentResponses.filter(res => !newResVendors.includes(res.vendorCode));
                    this.parentSupplierResponseIds = parentSupplierRes.map(res => res.id);
                    tempSupplierResponses = [...tempSupplierResponses, ...parentSupplierRes];
                }
            }

            // Merging items if nego event & parent event has different no. of items to resolve empty row issue. this is only for UI purpose.
            const mergedItemIds: string[] = this.getItemUniqueIds(tempSupplierResponses);
            tempSupplierResponses = tempSupplierResponses.map((res) => {
                res.showSupplierCols = false;

                // Check & add new empty item in supplier response BOQ which does not contains that item. this is only for UI purpose.
                const responseItemds: string[] = res.billOfQuantity.items.map(resItem => resItem.itemId);
                const extraItemIds: string[] = mergedItemIds.filter(itemId => !responseItemds.includes(itemId));
                if (extraItemIds.length){
                    extraItemIds.forEach((itemId) => {
                        const newEmptyItem = {
                            itemId: itemId,
                        };
                        res.billOfQuantity.items.push(newEmptyItem);
                    });
                }
                return res;
            });
            this.supplierResponses = tempSupplierResponses;

            // patch target details
            this.patchTargetCriteria();

            if (this.sourcingEvalution.awardCriteria) {
                this.form.controls.awardCriteria.setValue(this.sourcingEvalution.awardCriteria);
            }
            await this.changeCriteria(this.form.controls.awardCriteria.value, true);

            this.supplierResponses.forEach((supplierRes) => {
                // For evalution status
                const statusForm = this.tempsupplierEvaluationStatusForm(supplierRes);
                this.proposalsEvaluationStatus.push(statusForm);
                let evaluationStatus =
                    this.sourcingEvalution.proposalsEvaluationStatus?.find(
                        item => item.vendorCode === supplierRes.vendorCode
                    );

                // get proposalsEvaluationStatus of parent event
                if(!evaluationStatus) {
                    if(parentSourcingProposal){
                        evaluationStatus = parentSourcingProposal.proposalsEvaluationStatus?.find(
                            item => item.vendorCode === supplierRes.vendorCode
                        );
                    }
                }

                if (evaluationStatus) {
                    statusForm.patchValue(evaluationStatus);
                }
                if (supplierRes.status === SUPPLIER_STATUS.DISQUALIFIED
                    || supplierRes.status === SUPPLIER_STATUS.INVITED
                    || supplierRes.status === SUPPLIER_STATUS.PREVIEWED
                    || supplierRes.status === SUPPLIER_STATUS.ACCEPTED
                    || supplierRes.status === SUPPLIER_STATUS.REJECTED) {
                    statusForm.disable();
                }

                // For selection criteria
                const criteriaForm = this.supplierFormGroup(supplierRes);
                this.proposalSelectionCriteria.push(criteriaForm);
                let selectionCriteria =
                    this.sourcingEvalution.proposalSelectionCriteria?.find(
                        item => item.vendorCode === supplierRes.vendorCode
                    );

                // get proposalSelectionCriteria of parent event
                if(!selectionCriteria) {
                    if(parentSourcingProposal){
                        selectionCriteria = parentSourcingProposal.proposalSelectionCriteria?.find(
                            item => item.vendorCode === supplierRes.vendorCode
                        );
                    }
                }

                if (selectionCriteria) {
                    criteriaForm.patchValue(selectionCriteria);
                }
                if (supplierRes.status === SUPPLIER_STATUS.DISQUALIFIED
                    || supplierRes.status === SUPPLIER_STATUS.INVITED
                    || supplierRes.status === SUPPLIER_STATUS.PREVIEWED
                    || supplierRes.status === SUPPLIER_STATUS.ACCEPTED
                    || supplierRes.status === SUPPLIER_STATUS.REJECTED) {
                    criteriaForm.disable();
                }
            });

            // For documents
            this.form
                .get('sourcingProposalDoc')
                .setValue(this.sourcingEvalution.sourcingProposalDoc || []);


            if (this.sourcingEvalution.recommendation !== 'false') {
                this.form
                    .get('recommendation')
                    .setValue(this.sourcingEvalution.recommendation);
            }

            if (this.sourcingEvalution.paymentSchedule !== 'false') {
                this.form
                    .get('paymentSchedule')
                    .setValue(this.sourcingEvalution.paymentSchedule);
            }

            if (this.isFormDisable()) {
                this.form.disable();
            }
        }
    }

    getItemUniqueIds(supplierResponses: Array<SupplierResponse>): string[] {
        let mergedItemids: string[] = [];
        supplierResponses.map((res) => {
            mergedItemids = [...mergedItemids, ...res.billOfQuantity.items.map(item => item.itemId)];
        });
        return Array.from(new Set(mergedItemids));
    }

    patchTargetCriteria(): void {
        // patch target details
        const targetCriteria = {
            qualitySpecificationTarget: this.sourcingEvalution.qualitySpecificationTarget || '',
            leadTimeTarget: this.sourcingEvalution.leadTimeTarget || this.sourcingEvalution.expectedDeliveryLeadTime || '',
            costTarget: this.sourcingEvalution.costTarget || '',
            warrantyTarget: this.sourcingEvalution.warrantyTarget || ''
        };
        this.form.controls.proposalSelectionCriteria.patchValue(targetCriteria);
    }

    setProposalDetails(): void {
        this.loggedUser = curentUserInfo();
        this.showEscalateBtn = false;
        const isDraft = this.sourcingEvalution.status === RFX_FORM_STATUS.SOURCING_PROPOSAL_DRAFT;
        const isProposalPending = this.sourcingEvalution.status === RFX_FORM_STATUS.SOURCING_PROPOSAL_PENDING;
        const isBuyer = this.sourcingEvalution.creator.id === this.loggedUser.id;
        this.showDraftAndApprovalBtn = isDraft && (isBuyer || this.accessObj.isAdmin || (this.accessObj.isDeptAdmin && this.loggedUser?.departmentId == this.sourcingEvalution?.creator?.departmentId) || this.userAccessObject.isCreatorOrAssociateOwner);

        const sourcingProposalRoute = this.sourcingEvalution.sourcingProposalRoute;

        const associateOwnerIndex = this.sourcingEvalution.teamMembers?.findIndex(
            member =>
                member.userId === this.loggedUser.id &&
                member.viewStatus === this.viewerTypeEnum.ASSOCIATE_OWNER.toLowerCase()
        );
        const isAssociateOwner = associateOwnerIndex >= 0;

        this.proposalApprovalLevels = [];
        if (sourcingProposalRoute &&
            sourcingProposalRoute?.proposalLevels.length > 0) {
            this.proposalApprovalLevels = sourcingProposalRoute?.proposalLevels.sort((a, b) =>
                Number(a.levelSequence) > Number(b.levelSequence) ? 1 : -1
            );

            const approver = this.proposalApprovalLevels?.find(
                item =>
                    item.isCurrentActiveLevel &&
                    item.userId === this.loggedUser.id &&
                    item.levelStatus === ProposalLevelStatusEnum.PENDING
            );
            if (approver) {
                this.showApprovalForm = true;
            } else {
                this.showApprovalForm = false;
            }

            // Show Escalate Btn
            const isLastLevel = this.proposalApprovalLevels.findIndex(item =>
                    item.isCurrentActiveLevel &&
                    Number(item.levelSequence) < this.proposalApprovalLevels.length
            );
            
            this.showEscalateBtn = isProposalPending && (isBuyer || isAssociateOwner || this.accessObj.isAdmin || (this.accessObj.isDeptAdmin && this.loggedUser?.departmentId == this.sourcingEvalution?.creator?.departmentId) || this.userAccessObject.isCreatorOrAssociateOwner) && isLastLevel >= 0;
        } else {
            this.showApprovalForm = false;
        }


        if(this.isFromPR) {
            this.showDraftAndApprovalBtn = false;
            this.showApprovalForm = false;
        }
    }

    sortBoqItems(items: Array<BillOfQuantity>): Array<BillOfQuantity> {
        return items.sort((a, b) =>
            Number(a.itemSequence) > Number(b.itemSequence) ? 1 : -1
        );
    }

    clearAndSetAwardedSuppliers(fromUpdate: boolean): void {
        this.awardedSupllierFormArray.clear();

        const proposalItems = this.sortBoqItems(Object.assign([], this.sourcingEvalution.boq));
        const supplierResponses = Object.assign([], this.supplierResponses);
        const recommendedSupplierList: Array<{ vendorCode: string; companyName: string }> = [];
        const awardedSuppliers = Object.assign([], this.sourcingEvalution.awardedSupllier);

        proposalItems.forEach((boqItem) => {
            supplierResponses.forEach((supplierRes) => {
                if (supplierRes.status === SUPPLIER_STATUS.QUALIFIED && !this.parentSupplierResponseIds.includes(supplierRes.id)) {
                    const index = recommendedSupplierList.findIndex(supplier => supplier.vendorCode === supplierRes.vendorCode);

                    if (index < 0) {
                        recommendedSupplierList.push({
                            vendorCode: supplierRes.vendorCode,
                            companyName: supplierRes.supplier.companyName,
                        });
                    }
                }
            });
        });

        proposalItems.forEach((boqItem, index) => {
            const formGroup = this.fb.group({
                // id: [boqItem.id],
                itemId: [boqItem.itemId],
                itemSequence: [boqItem.itemSequence],
                itemName: [{value: boqItem.itemName, disabled: true}],
                recommendedSuppliers: [recommendedSupplierList],
                vendorCode: [fromUpdate && awardedSuppliers?.length > 0 ? this.getAwardedSupplierVendorCode(boqItem.itemId, awardedSuppliers)
                    : this.getSupplierVendorCode(boqItem.itemId)],
                itemDescription: [{value: boqItem.itemDescription, disabled: true}],
                equivalentBrandAllowed: [boqItem.equivalentBrandAllowed],
                brand: [boqItem.brand],
                model: [boqItem.model],
                costCenterId: [boqItem.costCenterId],
                wordOrderNo: [boqItem.wordOrderNo],
                internalOrderNoId: [boqItem.internalOrderNoId],
                partNumberId: [boqItem.partNumberId],

                uomId: [boqItem.uomId],
                uomCode: [boqItem.uom.code],
                uomDescription: [boqItem.uom.description],
                quantity: [boqItem.quantity],

                // Supplier item detail
                remark: [null],
                unitPrice: [null],
                totalAmountBeforeTax: [null],
                taxPercentage: [null],
                taxAmount: [null],
                totalAmountAfterTax: [null],

                supplierBrand: [''],
                supplierModel: [''],
                priceIncluded: [false],
                unableToQuote: [false],

                originalTaxAmount: [null],
                originalTaxPercentage: [null],
                originalTotalAmountAfterTax: [null],
                originalTotalAmountBeforeTax: [null],
                originalUnitPrice: [null],

                finalTaxAmount: [null],
                finalTaxPercentage: [null],
                finalTotalAmountAfterTax: [null],
                finalTotalAmountBeforeTax: [null],
                finalUnitPrice: [null]
            });

            this.awardedSupllierFormArray.push(formGroup);
            if (this.form.controls.awardCriteria.value === AwardCriteria.LOWEST_TOTAL_PRICE
                || this.form.controls.awardCriteria.value === AwardCriteria.LOWEST_ITEMIZED_PRICE) {
                formGroup.controls.vendorCode.disable();
                this.setSelectedSupplierDataIntoItem(formGroup);
            } else {
                formGroup.controls.vendorCode.enable();
            }

            if (fromUpdate && awardedSuppliers?.length > 0) {
                if (this.form.controls.awardCriteria.value === AwardCriteria.MANUAL_SELECTION) {
                    this.changeSupplier(formGroup, index);
                }
            }
        });
    }

    getSupplierVendorCode(itemId: string): any {
        if (this.overAllCostSummary) {
            if (
                this.form.controls.awardCriteria.value ===
                AwardCriteria.LOWEST_TOTAL_PRICE
            ) {
                return this.overAllCostSummary[0].vendorCode;
            } else if (
                this.form.controls.awardCriteria.value ===
                AwardCriteria.LOWEST_ITEMIZED_PRICE
            ) {
                const resItem = this.overAllCostSummary.find(
                    item => item.itemId === String(itemId)
                );
                return resItem ? resItem.vendorCode : null;
            }
        }
        return null;
    }

    getAwardedSupplierVendorCode(itemId: string, awardedSuppliers: Array<any>): any {
        if (awardedSuppliers) {
            const awardedItem = awardedSuppliers.find(
                item => item.itemId === String(itemId)
            );
            return awardedItem ? awardedItem.vendorCode : null;
        }
        return null;
    }

    // uploadFileHandler($event: any): void {
    //     const files = $event.target?.files;
    //     if (files?.length > 0) {
    //         const selectedFiles = [];
    //         const largeFileNames = [];
    //         for (const file of files) {
    //             const fileSize = Math.round(file.size / 1024);
    //             (fileSize > 51200) ? largeFileNames.push(file.name) : selectedFiles.push(file);
    //         }
    //         if (!this.isValidFilename(files.name)) {
    //             this.utils.showErrorSnack(
    //                 `Please ensure your file name does not any special character. Please rename the file and try again.`
    //             );
    //         }
    //         if (largeFileNames.length > 0) {
    //             this.utils.showErrorSnack(`${largeFileNames.join(',')} File size must be less than 50mb`);
    //             $event.target.value = '';
    //         }

    //         if (selectedFiles.length > 0) {
    //             $event.target.value = '';
    //             for (const file of selectedFiles) {
    //                 this.uploadFile(file);
    //             }
    //         }

    //     }
    // }
    uploadFileHandler($event: any): void {
        const files = $event.target?.files;
        if (files?.length > 0) {
            const selectedFiles = [];
            const largeFileNames = [];
            
            for (const file of files) {
                const fileSize = Math.round(file.size / 1024);
                
                if (fileSize > 51200) {
                    largeFileNames.push(file.name);
                } else if (!this.isValidFilename(file.name)) {
                    this.utils.showErrorSnack(
                        `Please ensure your file name does not contain any special character. Please rename the file and try again.`
                    );
                    return; 
                } else {
                    selectedFiles.push(file);
                }
            }
    
            if (largeFileNames.length > 0) {
                this.utils.showErrorSnack(`${largeFileNames.join(',')} File size must be less than 50mb`);
                $event.target.value = '';
            }
    
            if (selectedFiles.length > 0) {
                $event.target.value = '';
                for (const file of selectedFiles) {
                    this.uploadFile(file);
                }
            }
        }
    }
    
      
      private isValidFilename(filename: string): boolean {
        const disallowedCharactersPattern = /^[^!@#$%^&*]+$/;
      
        if (!disallowedCharactersPattern.test(filename)) {
          this.utils.showErrorSnack(
            `Please ensure your file name does not contain any special character. Please rename the file and try again.`
          );
          return false;
        }
      
        return true;
      }
      



    uploadFile(file: any): void {
        const imageFormData = new FormData();
        imageFormData.append('file', file);

        this.rfxService
            .uploadDocs(imageFormData)
            .subscribe((res: SupportingDocument) => {
                res.createdAt = res.createdDate;
                const docs: SupportingDocument[] = this.form.get('sourcingProposalDoc').value;
                docs.push(res);
                this.form.get('sourcingProposalDoc').setValue([...docs]);
            });
    }


    removeDoc(index: number, doc: SupportingDocument): void {
        // const { filename, originalname } = doc;
        // this.rfxService.deleteDocs([originalname]).subscribe(async () => {
        //     const docs: SupportingDocument[] = this.form.get('sourcingProposalDoc').value;
        //     docs.splice(index, 1);
        //     this.form.get('sourcingProposalDoc').setValue([...docs]);
        // });
        const docs: SupportingDocument[] = this.form.get('sourcingProposalDoc').value;
        docs.splice(index, 1);
        this.form.get('sourcingProposalDoc').setValue([...docs]);
    }

    getFileDetailObj(
        fileObj: any,
        fileName: string,
        uploadedDate: Date,
        filePath: string,
        fileId: string
    ): any {
        return {
            file: fileObj,
            fileName: fileName,
            uploadedDate: uploadedDate,
            id: fileId,
            filePath: filePath,
            offset: moment().utcOffset(),
        };
    }

    getFormValue(): any {
        const formValue = JSON.parse(JSON.stringify(this.form.getRawValue()));
        formValue.awardedSupllier.map(
            item => delete item.recommendedSuppliers
        );

        formValue.qualitySpecificationTarget = formValue.proposalSelectionCriteria.qualitySpecificationTarget;
        formValue.leadTimeTarget = formValue.proposalSelectionCriteria.leadTimeTarget;
        formValue.costTarget = formValue.proposalSelectionCriteria.costTarget;
        formValue.warrantyTarget = formValue.proposalSelectionCriteria.warrantyTarget;
        formValue.proposalSelectionCriteria = formValue.proposalSelectionCriteria.suppliers;

        formValue.proposalSelectionCriteria.forEach((criteria) => {
            delete criteria.status;
        });

        formValue.proposalsEvaluationStatus.forEach((status) => {
            delete status.status;
        });
        return formValue;
    }

    saveDraftOrApproval(action: string): void {
        if (this.form.invalid) {
            this.form.markAllAsTouched();
            return;
        }

        if (action === this.sourcingPropsalFormAction.SUBMIT) {
            if (!this.isValidAwardedSupllier() || !this.isValidItemPrice()) {
                return;
            }
        }

        this.rfxService
            .saveDraftOrApproval(this.getFormValue(), action)
            .subscribe((res) => {
                this.utils.showSuccessSnack(`Sourcing Proposal is submitted for approval successfully.`);
                this.refreshScreen();
            });
    }

    async changeCriteria(value, fromUpdate: boolean): Promise<void> {
        localStorage.removeItem(this.manualLocalStorageKey);
        const res = await lastValueFrom(
            this.rfxService.getOverAllCostByAwardCriteria(this.rfxId, value)
        );
        this.overAllCostSummary = res;
        this.clearAndSetAwardedSuppliers(fromUpdate);
        this.calculateOverAllCostSummary(res);
    }

    calculateOverAllCostSummary(overAllCostSummary: Array<any>, isManual: boolean = false): void {
        // this.newOverAllCostSummary = [];
        this.supplierWiseSummaryMap.clear();
        if (overAllCostSummary?.length > 0) {
            overAllCostSummary.forEach((item) => {
                if (this.supplierWiseSummaryMap.has(item.vendorCode)) {
                    const summary: OverAllCostSummary = this.supplierWiseSummaryMap.get(item.vendorCode);
                    summary.totalAmountWithTaxBeforNego += item.totalAmountWithTaxBeforNego;
                    summary.totalAmountWithTaxAfterNego += item.totalAmountWithTaxAfterNego;
                    this.supplierWiseSummaryMap.set(item.vendorCode, summary);
                } else {
                    this.supplierWiseSummaryMap.set(item.vendorCode, this.getSummaryObj(item));
                }
            });
        }
    }

    getSummaryObj(item: any): OverAllCostSummary {
        return {
            vendorCode: item.vendorCode,
            supplier: item.supplier,
            totalAmountWithTaxBeforNego: item.totalAmountWithTaxBeforNego,
            totalAmountWithTaxAfterNego: item.totalAmountWithTaxAfterNego,
        };
    }

    changeSupplier(boqItem: FormGroup, index: number): void {
        // find item from supplier
        const selectedItem = boqItem.getRawValue();

        if (this.form.controls.awardCriteria.value === AwardCriteria.MANUAL_SELECTION) {
            this.setSelectedSupplierDataIntoItem(boqItem);
        }

        this.rfxService
            .getOverAllCostByAwardCriteriaForManual(
                this.rfxId,
                selectedItem.itemId,
                selectedItem.vendorCode
            )
            .subscribe((data) => {
                if (!this.overAllCostSummary) {
                    this.overAllCostSummary = [data];
                } else {
                    this.overAllCostSummary[index] = data;
                }
                this.addManualItemToLocalStorage(data);
            });
    }

    addManualItemToLocalStorage(itemSummary: any): void {
        const manualItems: Array<any> = this.getLocalStorageItem();
        if (manualItems) {
            const index = manualItems.findIndex(item => item.itemId === itemSummary.itemId);
            if (index >= 0) {
                manualItems[index] = itemSummary;
            } else {
                manualItems.push(itemSummary);
            }
            this.setLocalStorageItem(manualItems);
        } else {
            this.setLocalStorageItem([itemSummary]);
        }
        this.calculateOverAllCostSummary(this.getLocalStorageItem(), true);
    }

    setLocalStorageItem(manualItems: Array<any>): void {
        localStorage.setItem(this.manualLocalStorageKey, JSON.stringify(manualItems));
    }

    getLocalStorageItem(): any {
        if (localStorage.getItem(this.manualLocalStorageKey)) {
            return JSON.parse(localStorage.getItem(this.manualLocalStorageKey));
        } else {
            return null;
        }
    }

    setSelectedSupplierDataIntoItem(itemGroup: FormGroup): void {
        const selectedItem = itemGroup.getRawValue();
        const supplierRes = this.sourcingEvalution?.supplierResponses.find(
            res => res.vendorCode === selectedItem.vendorCode
        );
        if (supplierRes) {
            const supplierItem = supplierRes.billOfQuantity.items.find(
                item => item.itemId.toString() === selectedItem.itemId.toString()
            );
            if (supplierItem) {
                selectedItem.supplierBrand = supplierItem.supplierBrand;
                selectedItem.supplierModel = supplierItem.supplierModel;
                selectedItem.priceIncluded = supplierItem.priceIncluded;
                selectedItem.unableToQuote = supplierItem.unableToQuote;

                selectedItem.remark = supplierItem.remark;
                selectedItem.unitPrice = supplierItem.unitPrice;
                selectedItem.totalAmountBeforeTax = supplierItem.totalAmountBeforeTax;
                selectedItem.taxPercentage = supplierItem.taxPercentage;
                selectedItem.taxAmount = supplierItem.taxAmount;
                selectedItem.totalAmountAfterTax = supplierItem.totalAmountAfterTax;

                selectedItem.originalTaxAmount = supplierItem.originalTaxAmount;
                selectedItem.originalTaxPercentage = supplierItem.originalTaxPercentage;
                selectedItem.originalTotalAmountAfterTax = supplierItem.originalTotalAmountAfterTax;
                selectedItem.originalTotalAmountBeforeTax = supplierItem.originalTotalAmountBeforeTax;
                selectedItem.originalUnitPrice = supplierItem.originalUnitPrice;

                selectedItem.finalTaxAmount = supplierItem.finalTaxAmount;
                selectedItem.finalTaxPercentage = supplierItem.finalTaxPercentage;
                selectedItem.finalTotalAmountAfterTax = supplierItem.finalTotalAmountAfterTax;
                selectedItem.finalTotalAmountBeforeTax = supplierItem.finalTotalAmountBeforeTax;
                selectedItem.finalUnitPrice = supplierItem.finalUnitPrice;
                itemGroup.patchValue({ ...selectedItem });
            }
        }
    }

    isFormDisable(): boolean {
        if (this.isFromPR) {
            return true;
        }

        if(this.sourcingEvalution){

            let approver = null;
            if (this.proposalApprovalLevels?.length > 0) {
                approver = this.proposalApprovalLevels?.find(
                    item => item.userId === this.loggedUser?.id
                );
            }

            if (approver) {
                return true;
            } else {
                return (
                    this.sourcingEvalution.status !==
                    RFX_FORM_STATUS.SOURCING_PROPOSAL_DRAFT
                );
            }
        }

        return false;
    }

    approveRejectAction(action: ProposalLevelStatusEnum): void {
        this.showRemarkError = false;
        if (action === ProposalLevelStatusEnum.APPROVED) {
            this.approveRejectProposal(action);
        } else {
            const remark = this.approvalForm.controls.remark.value;
            this.showRemarkError = remark == null || remark === '';
            if (!this.showRemarkError) {
                this.approveRejectProposal(action);
            }
        }
    }

    approveRejectProposal(action: ProposalLevelStatusEnum): void {
        const formValue = this.approvalForm.value;
        formValue.rfxId = Number(this.rfxId);
        formValue.status = action;

        this.rfxService.approveRejectProposal(formValue).subscribe((res) => {
            if (res) {
                this.utils.showSuccessSnack(
                    `Sourcing proposal ${action.toLowerCase()} successfully`
                );
                this.back();
                // this.rfxSourcingProposalByRfxId();
            }
        });
    }

    escalateOnAction(): void {
        const dialogRef = this.dialog.open(RfxEscalatePopupComponent, {
            panelClass: 'eventpop',
            minWidth: '30vw',
            data: {
                rfxId: Number(this.rfxId),
                sourcingApprovalStage: RfxApprovalStageEnum.SOURCING_PROPOSAL_APPROVAL,
            },
        });

        dialogRef.afterClosed().subscribe((dialogResult) => {
            if (dialogResult) {
                this.refreshScreen();
            }
        });
    }

    getDownloadPayload(): DownloadPayload {
        const tempArray: any = [];
        this.supplierWiseSummaryMap.forEach((value, key) => {
            tempArray.push({
                vendorCode: key,
                overAllCostSummary: value
            });
        });
        const obj = {
            rfxId: Number(this.rfxId),
            awardedSupllier: this.getFormValue().awardedSupllier,
            supplierWiseSummary: tempArray
        };

        console.log('obj ::: ', obj);

        return obj;
    }

    //validation for empty recommended supplier in manual case
    isValidAwardedSupllier(): boolean {
        const formValue = this.getFormValue();
        if (this.form.controls.awardCriteria.value === AwardCriteria.MANUAL_SELECTION) {
            const filteredItems = formValue.awardedSupllier.filter(item => !item.vendorCode || item.vendorCode === '');
            if (filteredItems.length > 0) {
                this.utils.showMultilineErrorSnack(
                    filteredItems.map(item => `Please select recommended supplier for item ${item.itemSequence}`)
                );
                return false;
            }
        }
        return true;
    }

    isValidItemPrice(): boolean {
        const formValue = this.getFormValue();
        const filteredItems = formValue.awardedSupllier.filter(item => item.unableToQuote
            && (item.originalTotalAmountAfterTax !== 'N/A' && item.originalTotalAmountAfterTax === 0));
        if (filteredItems.length > 0) {
            this.utils.showMultilineErrorSnack(
                filteredItems.map(item => `Unable to award for item ${item.itemSequence}. Please recheck award details`)
            );
            return false;
        }
        return true;
    }

    downloadPDFSummary(): void {
        if (this.isValidAwardedSupllier()) {
            this.rfxService.downloadPDFSummary(this.sourcingEvalution.rfxSequenceId, this.getDownloadPayload());
        }
    }

    downloadPDFDetail(): void {
        if (this.isValidAwardedSupllier()) {
            this.rfxService.downloadPDFDetail(this.sourcingEvalution.rfxSequenceId, this.getDownloadPayload());
        }
    }

    downloadSourcingProposal(): void {
        if (this.isValidAwardedSupllier()) {
            this.rfxService.downloadSourcingProposalExcel(this.sourcingEvalution.rfxSequenceId, this.getDownloadPayload());
        }
    }

    downloadDoc(doc: SupportingDocument): void {
        if (doc.path) {
            const fileNameArray = doc.originalname.split('.');
            const extension: string = fileNameArray[fileNameArray.length - 1];
            if (['pdf'].includes(extension)) {
                this.documentService.downloadAndOpenDocs(doc.originalname, doc.filename, true);
            } else {
                window.open(doc.path, '_blank');
            }
        }
    }

    refreshScreen(): void {
        this.refreshEvent.emit('Proposal');
        this.rfxSourcingProposalByRfxId();
    }

    navigateToSupplierMaintenance(vendorCode: string): void {
        if (vendorCode) {
            const url = this.router.serializeUrl(this.router.createUrlTree(['/supplier/list/maintenance'], {
                queryParams: { code: vendorCode },
            }));
            window.open(url, '_blank');
        }
    }

    back(): void {
        this.router.navigate(['/rfx']);
    }
}

