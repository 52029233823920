import { BUYER_RESPONSE_STATUS } from 'app/enums/rfx-enum/rfx.enum';
import { BillOfQuantity } from '../bill-of-quantity';
import { PurchaseOrg } from '../purchase-org';
import { Questionnaire } from '../rfx-interface/questionnaire.interface';
import { ApprovalRouteLevel, ProposalApprovalRoute } from '../rfx-interface/rfx-common.interface';
import {
    RfxApprovalHistory,
    RfxForm,
    RfxRequestor,
    UserDetail,
} from '../rfx-interface/rfx-form.interface';
import { Supplier } from '../supplier';

import { User } from '../users';
import { Warranty } from '../Warranty';

export interface SourcingEvalution {
    id: number;
    title: string;
    closingDate: string;
    status: string;
    boq: Array<BillOfQuantity>;
    requestor: Array<RfxRequestor>;
    creator: User;

    supplierResponses: Array<SupplierResponse>;
    questionnaire: Array<Questionnaire>;

    envelopes: Array<EvalutionEnvelope>;
    // evaluationHistories: Array<EvalutionHistory>;
    // supplierEvaluationStatus: Array<EvaluationStatusForm>;
    // selectionCriteria: Array<EvaluationSelectionCriteria>;
    // supplierEvaluationDoc: Array<SupportingDocument>;

    awardCriteria: string;
    awardedSupllier: Array<any>; // need to convert into interface
    recommendation: string;
    paymentSchedule: string;

    isNegotiation: boolean;
    purchOrg: PurchaseOrg;

    // Target Selection criteria
    qualitySpecificationTarget: string;
    leadTimeTarget: string;
    costTarget: string;
    warrantyTarget: string;

    // Auto fill from pre-pr
    expectedDeliveryLeadTime: string;
    warrant: Warranty;
    rfxSequenceId: number;
}

export interface SourcingProposal {
    id: number;
    title: string;
    closingDate: string;
    status: string;
    boq: Array<BillOfQuantity>;
    requestor: Array<RfxRequestor>;
    creator: User;

    supplierResponses: Array<SupplierResponse>;
    questionnaire: Array<Questionnaire>;

    awardCriteria: string;
    awardedSupllier: Array<any>; // need to convert into interface
    recommendation: string;
    paymentSchedule: string;

    proposalSelectionCriteria: Array<EvaluationSelectionCriteria>;
    proposalsEvaluationStatus: Array<EvaluationStatusForm>;
    sourcingProposalDoc: Array<SupportingDocument>;
    sourcingProposalRoute: ProposalApprovalRoute;

    isNegotiation: boolean;
    parentEventId: number;
    originalEventId: number;
    purchOrg: PurchaseOrg;

    // Target Selection criteria
    qualitySpecificationTarget: string;
    leadTimeTarget: string;
    expectedDeliveryLeadTime: string;
    costTarget: string;
    warrantyTarget: string;
    teamMembers: Array<any>; // need to change to interface
    rfxSequenceId: number;
}

export interface EvalutionEnvelope {
    id?: number;
    envelopeName: string;
    envelopeSequence: number;
    envelopStatus: string;
    isEnvelopOpened: boolean;
    evaluationOwnerId: number;
    envelopeEvaluators: Array<UserDetail>;
    envelopeApprovers: Array<ApprovalRouteLevel>;
    selectionCriteria: Array<EvaluationSelectionCriteria>;
    supplierEvaluationStatus: Array<EvaluationStatusForm>;
    supplierEvaluationDoc: Array<SupportingDocument>;
    evaluationHistories: Array<EvalutionHistory>;
    showAttachmentOption: boolean;
    attachmentType: Array<string>;
    evaluationOwner: { id: number; name: string };
    canApproveWorkflow: boolean;
    openingSequence: boolean;
    readonly: boolean;
    approvalCommentHistory?: Array<EvalutionEnvelopeApprovalHistory>;
}

export interface SupplierResponse {
    id?: number;
    rfxId: number;
    supplierId: number; // not in used now do replace with vendorCode
    rfxSupplierId: number;
    answers: Array<SupplierAnswer>;
    messages: Array<SupplierMessages>;
    billOfQuantity: SupplierBOQ;
    meetingAttendees: Array<SupplierMeetingAttendees>;
    supportingDocuments: Array<SupportingDocument>;
    rfx?: RfxForm;
    status: string; // this is supplier status used in buyer evalution
    vendorCode: string;
    supplier: Supplier;
    parentSupportingDocuments: Array<SupportingDocument>;

    finalStatus: string;
    originalStatus: string;
    originalSupplierRemark: string;
    finalSupplierRemark: string;
    showSupplierCols?: boolean; // used for FE only

    //only for internal use
    sequence: number;
    isRecommended: boolean;
}

export interface SupplierBOQ {
    finalRemark: string;
    originalRemark: string;
    remark: string;
    totalAmountBeforeTax: number;
    totalTax: number;
    grandTotal: number;
    variance: number;
    items: Array<BillOfQuantity>;

    originalGrandTotal: number;
    originalTotalAmountBeforeTax: number;
    originalTotalTax: number;
    originalVariance: number;

    finalGrandTotal: number;
    finalTotalAmountBeforeTax: number;
    finalTotalTax: number;
    finalVariance: number;
}

export interface SupplierAnswer {
    id?: number;
    sNo: number;
    questionId: number;
    answer: any;
    answerSupportingDocuments: SupportingDocument;
}

export interface SupplierMessageInput {
    vendorCodes: string[];
    buyerId: number;
    subject: string;
    content: string;
    supportingDocuments: Array<SupportingDocument>;
    isSupplier: boolean;
    supplierResponseId: number;
}

export interface SupplierMessages {
    id: number;
    supplierResponseId: number;
    vendorCode: string;
    buyerId: number;
    subject: string;
    content: string;
    supportingDocuments: Array<SupportingDocument>;
    supplier: Supplier;
    buyer: User;
    createdAt: string;
    isSupplier: boolean;
}
export interface SupplierMeetingAttendees {
    id: number;
    name: string;
    email: string;
    phoneNumber: number | string;
    didTheyAttend: boolean;
    rfxMeetingId: number;
    buyerResponseStatus: BUYER_RESPONSE_STATUS;
    supplierName?: string; // for FE
    concluded?: boolean; // for FE
}

export interface SupportingDocument {
    id: number;
    filename: string;
    originalname: string;
    path: string;
    description?: string;
    createdAt?: string;
    createdDate?: string;
    updatedAt?: string;
    uploadDate?: string;

    rfxId?: number;
    availability?: string;
    envelopeId: number;
    isParentCopy?: boolean;
    invoiceId?: number;
}

export interface SupplierStatusChangeInput {
    supplierId: number;
    status: string;
    buyerRemark: string;
}

export interface EvalutionHistory {
    id: number;
    userId: number;
    comment: string;
    createdAt: string;
    updatedAt: string;
    userDetails: User;
}

export interface EvaluationStatusForm {
    id: number;
    envelopeId: number;
    supplierId: number; // not in used now do replace with vendorCode
    vendorCode: string;
    supplierEvaluation: string;
    remark: string;
    taxExemtionNo: string;
    supplier: Supplier;
}

export interface EvaluationSelectionCriteria {
    id: number;
    envelopeId: number;
    cost: string;
    leadTime: string;
    qualitySpecification: string;
    supplierId: number; // not in used now do replace with vendorCode
    vendorCode: string;
    supplier: Supplier;
    target: string;
    warranty: string;
}

export interface OverAllCostSummary {
    vendorCode: string;
    supplier: Supplier;
    totalAmountWithTaxBeforNego: number;
    totalAmountWithTaxAfterNego: number;
}

export interface DownloadPayload {
    rfxId: number;
    awardedSupllier?: Array<BillOfQuantity>;
    supplierWiseSummaryMap?: Map<string, OverAllCostSummary>;
    supplierWiseSummary?: Array<{ vendorCode: string; overAllCostSummary: OverAllCostSummary }>;
}

export interface EvaluationItemDelete {
    rfxId: number;
    envelopeId: number;
    itemId: string; // unique itemId not db id
    action: EvaluationItemDeleteAction;
}

export interface RfxEnvelopApprovalHistory {
    id: number;
    envelopes: EvalutionEnvelope[];
}

export interface EvalutionEnvelopeApprovalHistory {
    id: number;
    envelopeId: number;
    actionById: number;
    actionBy: User;
    action: string;
    actionDate: Date;
    description: string;
    updatedAt: Date;
}

export enum EnvelopStatusEnum {
    PENDING = 'Pending',
    PENDING_APPROVAL = 'Pending Approval',
    PENDING_EVALUTION = 'Pending Evaluation',
    PENDING_BUYER_EVALUATION = 'Pending Buyer Evaluation',
    PENDING_REQUESTOR_EVALUATION = 'Pending Requestor Evaluation',
    COMPLETE = 'Completed',
    CLOSED = 'Closed',
}

export enum EnvelopLevelStatusEnum {
    APPROVED = 'Approved',
    REJECTED = 'Rejected',
    PENDING = 'Pending',
    ESCALATED = 'Escalated'
}

export enum SourcingPropsalFormAction {
    SUBMIT = 'Submit',
    DRAFT = 'Draft',
}

export enum EvaluationItemDeleteAction {
    DELETE = 'delete',
    UNDO = 'undo'
}
