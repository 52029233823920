<div class="md:flex flex-row">
    <mat-label class="font-medium text-gray-900 md:w-2/12">Purchasing Org:</mat-label>
    <p class="text-gray-500 md:w-2/12">{{sourcingEvalution?.purchOrg?.name}}</p>

    <div class="md:w-8/12 md:text-right">
        <button class="mat-primary rounded pl-0 pr-1 mr-2 mb-2" mat-raised-button (click)="downloadPDFSummary()">
            <mat-icon class="mr-1 min-h-[40px] min-w-[28px] bg-[#111725] rounded rounded-r-none">save_alt</mat-icon>
            Download PDF Summary
        </button>

        <button class="mat-primary rounded pl-0 pr-1 mr-2 mb-2" mat-raised-button
            (click)="downloadPDFDetail()">
            <mat-icon class="mr-1 min-h-[40px] min-w-[28px] bg-[#111725] rounded rounded-r-none">save_alt</mat-icon>
            Download PDF Details
        </button>

        <button class="mat-primary rounded pl-0 pr-1 mb-2" mat-raised-button (click)="downloadSourcingProposal()">
            <mat-icon class="mr-1 min-h-[40px] min-w-[28px] bg-[#111725] rounded rounded-r-none">save_alt</mat-icon>
            Download Sourcing Proposal
        </button>
    </div>

</div>
<div class="md:flex flex-row">
    <mat-label class="font-medium text-gray-900 md:w-2/12">Event ID:</mat-label>
    <p class="text-gray-500 md:w-10/12">{{ rfxSequenceId || '' }}</p>
</div>
<div class="md:flex flex-row mt-6">
    <mat-label class="font-medium text-gray-900 md:w-2/12">Event Buyer:</mat-label>
    <p class="text-gray-500 md:w-10/12">
        {{ sourcingEvalution?.creator?.name }}
        <!-- {{ sourcingEvalution?.creator?.emailAddress }} -->
    </p>
</div>

<form [formGroup]="form" class="blue-readonly">
    <div class="my-6 text-gray-900 font-bold border block md:flex justify-between items-center award-criteria">
        <div class="md:flex items-center w-full">
            <span class="px-4">Award Criteria:</span>
            <mat-form-field class="fuse-mat-dense md:w-3/12">
                <mat-select placeholder="Select Award Criteria" formControlName="awardCriteria"
                    (selectionChange)="changeCriteria($event.value, false)">
                    <mat-option *ngFor="let criteria of awardCriteriaEnum | keyvalue"
                        [value]="criteria.value">
                        {{ criteria.value }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>

    <div class="">
        <div class="table-box1 overflow-x-auto bill border">
            <table>
                <tr>
                    <!-- <th class="min-w-[50px]">Action</th> -->
                    <th class="col1"></th>
                    <th class="col2 text-black font-medium text-right pr-3"></th>
                    <th [colSpan]="showOriginalRfqCols ? 7 : 3" class="border-l text-center">
                        <div class="flex flex-row justify-between items-center">
                            <span></span>
                            <span>Original RFQ</span>
                            <button mat-icon-button type="button" (click)="showOriginalRfqCols = !showOriginalRfqCols">
                                <mat-icon *ngIf="showOriginalRfqCols">chevron_left</mat-icon>
                                <mat-icon *ngIf="!showOriginalRfqCols">chevron_right</mat-icon>
                            </button>
                        </div>
                    </th>
                    <!-- <th colspan="7"
                        class="text-center">Original RFQ</th>
                    <th class="text-center"></th> -->
                    <ng-container *ngFor="
                            let supplierRes of supplierResponses
                        ">
                        <th [colSpan]="supplierRes.showSupplierCols ? 8 : 4" class="text-center border-l border-gray-400">
                            <div class="flex flex-row justify-between items-center">
                                <span></span>
                                <ng-container *ngIf="[rfxFormStatusEnum.SOURCING_PROPOSAL_DRAFT,
                                                            rfxFormStatusEnum.SOURCING_PROPOSAL_PENDING,
                                                        	rfxFormStatusEnum.FINISHED].includes(sourcingEvalution?.status) else companyNameRef">
                                    <span class="text-primary underline cursor-pointer" (click)="navigateToSupplierMaintenance(supplierRes.vendorCode)">
                                        {{ supplierRes.supplier?.companyName }}
                                    </span>
                                </ng-container>
                                <ng-template #companyNameRef>
                                    <span>{{ supplierRes.supplier?.companyName }}</span>
                                </ng-template>
                                <button mat-icon-button type="button" (click)="supplierRes.showSupplierCols = !supplierRes.showSupplierCols">
                                    <mat-icon *ngIf="supplierRes.showSupplierCols">chevron_left</mat-icon>
                                    <mat-icon *ngIf="!supplierRes.showSupplierCols">chevron_right</mat-icon>
                                </button>
                            </div>
                        </th>
                    </ng-container>
                </tr>

                <tr>
                    <!-- <th colspan="8"></th> -->
                    <th class="col1 text-center">No</th>
                    <th class="border-l col2">Item Name</th>
                    <th class="border-l">Description</th>
                    <th class="border-l" *ngIf="showOriginalRfqCols">Equivalent Brand Allowed</th>
                    <th class="border-l" *ngIf="showOriginalRfqCols">Brand</th>
                    <th class="border-l" *ngIf="showOriginalRfqCols">Model</th>
                    <th class="border-l" *ngIf="showOriginalRfqCols">UOM</th>
                    <th class="border-l">Quantity</th>
                    <th class="border-l min-w-[150px]">
                        Supplier Recommendation
                    </th>
                    <ng-container *ngFor="
                            let supplierRes of supplierResponses
                        ">
                        <th class="border-l border-gray-400 price-include"
                            [ngClass]="{'yes': supplierRes.status !== supplierStatusEnum.QUALIFIED}"
                            *ngIf="supplierRes.showSupplierCols">
                            Price Included /Unable to quote
                        </th>
                        <th class="border-l"
                            [ngClass]="{'yes': supplierRes.status !== supplierStatusEnum.QUALIFIED}"
                            *ngIf="supplierRes.showSupplierCols">
                            Remark
                        </th>
                        <th class="border-l text-center"
                            [ngClass]="{'yes': supplierRes.status !== supplierStatusEnum.QUALIFIED}"
                            *ngIf="supplierRes.showSupplierCols">
                            Brand
                        </th>
                        <th class="border-l text-center"
                            [ngClass]="{'yes': supplierRes.status !== supplierStatusEnum.QUALIFIED}"
                            *ngIf="supplierRes.showSupplierCols">
                            Model
                        </th>
                        <th class="border-l text-center"
                            [ngClass]="{'yes': supplierRes.status !== supplierStatusEnum.QUALIFIED,
                            'border-gray-400': !supplierRes.showSupplierCols}">
                            Original Unit Price(MYR)
                        </th>
                        <th class="border-l text-center"
                            [ngClass]="{'yes': supplierRes.status !== supplierStatusEnum.QUALIFIED}">
                            Final Unit Price(MYR)
                        </th>
                        <th class="border-l text-center"
                            [ngClass]="{'yes': supplierRes.status !== supplierStatusEnum.QUALIFIED}">
                            Original Total with Tax(MYR)
                        </th>
                        <th class="border-l text-center"
                            [ngClass]="{'yes': supplierRes.status !== supplierStatusEnum.QUALIFIED}">
                            Final Total Price with Tax(MYR)
                        </th>
                    </ng-container>
                </tr>

                <ng-container formArrayName="awardedSupllier"
                    *ngFor="
                        let boqItem of form.get('awardedSupllier')?.controls;
                        let i = index
                    ">
                    <tr [formGroupName]="i">
                        <!-- <td class="min-w-[50px]"><button mat-icon-button>
                            <mat-icon>delete</mat-icon>
                        </button></td> -->
                        <td class="col1 border-r text-center">{{ i + 1 }}</td>
                        <td class="col2 text-area-container">
                            <mat-form-field class="fuse-mat-dense boq-item-text-area">
                                <textarea matInput class="small-text-area" rows="1" formControlName="itemName"></textarea>
                            </mat-form-field>
                        </td>
                        <td class="text-area-container border-l">
                            <mat-form-field class="fuse-mat-dense boq-item-text-area">
                                <textarea matInput class="small-text-area" rows="1" formControlName="itemDescription"></textarea>
                            </mat-form-field>
                        </td>
                        <td *ngIf="showOriginalRfqCols">{{ boqItem.get("equivalentBrandAllowed").value }}</td>
                        <td *ngIf="showOriginalRfqCols">{{ boqItem.get("brand").value }}</td>
                        <td *ngIf="showOriginalRfqCols">{{ boqItem.get("model").value }}</td>
                        <td *ngIf="showOriginalRfqCols">{{ boqItem.get("uomCode").value + ' - ' + boqItem.get("uomDescription").value }}</td>
                        <td>
                            {{ boqItem.get("quantity").value | number: ".2-2" }}
                        </td>
                        <td>
                            <mat-form-field class="fuse-mat-dense w-full pr-2">
                                <mat-select (selectionChange)="
                                        changeSupplier(boqItem, i)
                                    "
                                    [placeholder]="'Select Supplier'"
                                    formControlName="vendorCode">
                                    <mat-option *ngFor="
                                            let opt of boqItem.get(
                                                'recommendedSuppliers'
                                            )?.value
                                        "
                                        [value]="opt.vendorCode">
                                        {{ opt.companyName }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </td>

                        <ng-container *ngFor="
                            let supplierRes of supplierResponses
                        ">
                            <ng-container *ngFor="
                                let item of supplierRes.billOfQuantity.items
                            ">
                                <ng-container *ngIf="item.itemId === boqItem.get('itemId').value">
                                    <td class="border-l border-gray-400 price-include"
                                        [ngClass]="{'yes': supplierRes.status !== supplierStatusEnum.QUALIFIED}"
                                        *ngIf="supplierRes.showSupplierCols">
                                        {{ item.priceIncluded ? "PI" : item.unableToQuote ? "UTQ" : "" }}
                                    </td>
                                    <td [ngClass]="{'yes': supplierRes.status !== supplierStatusEnum.QUALIFIED}"
                                        *ngIf="supplierRes.showSupplierCols">
                                        {{item.remark}}
                                    </td>
                                    <td [ngClass]="{'yes': supplierRes.status !== supplierStatusEnum.QUALIFIED}"
                                        *ngIf="supplierRes.showSupplierCols">
                                        {{ item.supplierBrand }}
                                    </td>
                                    <td [ngClass]="{'yes': supplierRes.status !== supplierStatusEnum.QUALIFIED}"
                                        *ngIf="supplierRes.showSupplierCols">
                                        {{ item.supplierModel }}
                                    </td>
                                    <td class="text-right price-col"
                                        [ngClass]="{'yes': supplierRes.status !== supplierStatusEnum.QUALIFIED,
                                        'border-l border-gray-400': !supplierRes.showSupplierCols}">
                                        {{
                                        item?.originalUnitPrice !== "N/A"
                                        ? (item?.originalUnitPrice
                                        | number: ".2-2")
                                        : item?.originalUnitPrice
                                        }}
                                    </td>
                                    <td class="text-right price-col"
                                        [ngClass]="{'yes': supplierRes.status !== supplierStatusEnum.QUALIFIED}">
                                        {{
                                        item?.finalUnitPrice !== "N/A"
                                        ? (item?.finalUnitPrice
                                        | number: ".2-2")
                                        : item?.finalUnitPrice
                                        }}
                                    </td>
                                    <td class="text-right price-col"
                                        [ngClass]="{'yes': supplierRes.status !== supplierStatusEnum.QUALIFIED,
                                        'final-price-indicator': !this.sourcingEvalution?.isNegotiation
                                        && boqItem.get('vendorCode').value
                                        && boqItem.get('vendorCode').value === supplierRes.vendorCode}">
                                        {{
                                        item?.originalTotalAmountAfterTax !==
                                        "N/A"
                                        ? (item?.originalTotalAmountAfterTax
                                        | number: ".2-2")
                                        : item?.originalTotalAmountAfterTax
                                        }}
                                    </td>
                                    <td class="text-right pr-3 price-col" [ngClass]="{'yes': supplierRes.status !== supplierStatusEnum.QUALIFIED,
                                        'final-price-indicator': this.sourcingEvalution?.isNegotiation
                                        && boqItem.get('vendorCode').value
                                        && boqItem.get('vendorCode').value === supplierRes.vendorCode}">
                                        {{
                                        item?.finalTotalAmountAfterTax !==
                                        "N/A"
                                        ? (item?.finalTotalAmountAfterTax
                                        | number: ".2-2")
                                        : item?.finalTotalAmountAfterTax
                                        }}
                                    </td>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                    </tr>
                </ng-container>

                <tr>
                    <td class="col1"></td>
                    <td class="col2 text-black font-medium text-right pr-3">Supplier Status</td>
                    <td class="border-l"></td>
                    <td [colSpan]="showOriginalRfqCols ? 6 : 2"></td>
                    <ng-container *ngFor="
                        let supplierRes of supplierResponses
                    ">
                        <td [colSpan]="supplierRes.showSupplierCols ? 7 : 3" class="text-right font-medium border-l border-gray-400"
                        [ngClass]="{'yes': supplierRes.status !== supplierStatusEnum.QUALIFIED}">
                            {{ supplierRes.originalStatus }}
                        </td>
                        <td class="text-right font-medium"
                        [ngClass]="{'yes': supplierRes.status !== supplierStatusEnum.QUALIFIED}">
                            {{ supplierRes.finalStatus }}
                        </td>
                    </ng-container>
                </tr>

                <tr>
                    <td class="col1"></td>
                    <td class="col2 text-black font-medium text-right pr-3">Grand Total (MYR)</td>
                    <td class="border-l"></td>
                    <td [colSpan]="showOriginalRfqCols ? 6 : 2"></td>
                    <ng-container *ngFor="
                        let supplierRes of supplierResponses
                    ">
                        <td [colSpan]="supplierRes.showSupplierCols ? 7 : 3" class="text-right font-medium border-l border-gray-400"
                        [ngClass]="{'yes': supplierRes.status !== supplierStatusEnum.QUALIFIED}">
                            {{ supplierRes.billOfQuantity.originalGrandTotal === "N/A"
                                ? supplierRes.billOfQuantity.originalGrandTotal
                                : (supplierRes.billOfQuantity.originalGrandTotal | number: ".2-2")
                            }}
                        </td>
                        <td class="text-right font-medium"
                        [ngClass]="{'yes': supplierRes.status !== supplierStatusEnum.QUALIFIED}">
                            {{ supplierRes.billOfQuantity.finalGrandTotal === "N/A"
                                ? supplierRes.billOfQuantity.finalGrandTotal
                                : (supplierRes.billOfQuantity.finalGrandTotal | number: ".2-2")
                            }}
                        </td>
                    </ng-container>
                </tr>

                <tr>
                    <td class="col1"></td>
                    <td class="col2 text-black font-medium text-right pr-3">Variance (%)</td>
                    <td class="border-l"></td>
                    <td [colSpan]="showOriginalRfqCols ? 6 : 2"></td>
                    <ng-container *ngFor="
                        let supplierRes of supplierResponses
                    ">
                        <td [colSpan]="supplierRes.showSupplierCols ? 7 : 3" class="text-right font-medium border-l border-gray-400"
                        [ngClass]="{'yes': supplierRes.status !== supplierStatusEnum.QUALIFIED}">
                            {{ supplierRes.billOfQuantity.originalVariance === "N/A"
                                ? supplierRes.billOfQuantity.originalVariance
                                : (supplierRes.billOfQuantity.originalVariance | number: ".2-2") + ' %'
                            }}
                        </td>
                        <td class="text-right font-medium"
                        [ngClass]="{'yes': supplierRes.status !== supplierStatusEnum.QUALIFIED}">
                            {{ supplierRes.billOfQuantity.finalVariance === "N/A"
                                ? supplierRes.billOfQuantity.finalVariance
                                : (supplierRes.billOfQuantity.finalVariance | number: ".2-2") + ' %'
                            }}
                        </td>
                    </ng-container>
                </tr>

                <tr>
                    <td class="col1"></td>
                    <td class="col2 text-black font-medium text-right pr-3">Supplier Remark</td>
                    <td class="border-l"></td>
                    <td [colSpan]="showOriginalRfqCols ? 6 : 2"></td>
                    <ng-container *ngFor="let supplierRes of supplierResponses">
                        <td [colSpan]="supplierRes.showSupplierCols ? 6 : 2" class="border-l border-gray-400"
                        [ngClass]="{'yes': supplierRes.status !== supplierStatusEnum.QUALIFIED}"></td>
                        <td class="text-right text-black font-medium"
                        [ngClass]="{'yes': supplierRes.status !== supplierStatusEnum.QUALIFIED}">
                            {{ supplierRes.status === supplierStatusEnum.REJECTED
                                ? supplierRes.supplierRemark : supplierRes.billOfQuantity.originalRemark}}
                        </td>
                        <td class="text-right font-medium"
                        [ngClass]="{'yes': supplierRes.status !== supplierStatusEnum.QUALIFIED}">
                            {{ supplierRes.billOfQuantity.finalRemark }}
                        </td>
                    </ng-container>
                </tr>

                <tr>
                    <td class="col1"></td>
                    <td class="col2 text-black font-medium text-right pr-3">Original Supplier Quotation</td>
                    <td class="border-l"></td>
                    <td [colSpan]="showOriginalRfqCols ? 6 : 2"></td>
                    <ng-container *ngFor="
                        let supplierRes of supplierResponses
                    ">
                        <td [colSpan]="supplierRes.showSupplierCols ? 8 : 4" class="text-right text-primary font-medium border-l border-gray-400"
                        [ngClass]="{'yes': supplierRes.status !== supplierStatusEnum.QUALIFIED}">
                            <ng-container *ngFor="let doc of this.sourcingEvalution?.isNegotiation
                                ? supplierRes.parentSupportingDocuments : supplierRes.supportingDocuments">
                                <p>
                                    <a class="cursor-pointer text-primary"
                                        (click)="downloadDoc(doc)">{{ doc.filename }}</a>
                                </p>
                            </ng-container>
                        </td>
                    </ng-container>
                </tr>

                <tr *ngIf="this.sourcingEvalution?.isNegotiation">
                    <td class="col1"></td>
                    <td class="col2 text-black font-medium text-right pr-3">Final Supplier Quotation</td>
                    <td class="border-l"></td>
                    <td [colSpan]="showOriginalRfqCols ? 6 : 2"></td>
                    <ng-container *ngFor="
                        let supplierRes of supplierResponses
                    ">
                        <td [colSpan]="supplierRes.showSupplierCols ? 8 : 4" class="text-right text-primary font-medium border-l border-gray-400"
                        [ngClass]="{'yes': supplierRes.status !== supplierStatusEnum.QUALIFIED}">
                            <ng-container *ngFor="let doc of supplierRes.supportingDocuments">
                                <p>
                                    <a class="cursor-pointer text-primary"
                                        (click)="downloadDoc(doc)">{{ doc.filename }}</a>
                                </p>
                            </ng-container>
                        </td>
                    </ng-container>
                </tr>
            </table>
        </div>
    </div>

    <!-- Overall cost summary -->
    <mat-label class="mt-6 px-6 py-3 text-gray-900 font-bold border block border-b-0">
        Overall Cost Summary
    </mat-label>
    <div class="p-6 border">
        <div class="table-box1 overflow-x-auto">
            <table class="border md:table-fixed">
                <tr>
                    <th>Awarded Supplier</th>
                    <!-- Before Negotiation -->
                    <th>Original Total with Tax(MYR)</th>
                    <!-- After Negotiation -->
                    <th>Final Total with Tax(MYR)</th>
                    <th>Price Difference</th>
                </tr>

                <tr *ngFor="let summary of supplierWiseSummaryMap| keyvalue">
                    <td>
                        <ng-container *ngIf="[rfxFormStatusEnum.SOURCING_PROPOSAL_DRAFT,
                            rfxFormStatusEnum.SOURCING_PROPOSAL_PENDING,
                        	rfxFormStatusEnum.FINISHED].includes(sourcingEvalution?.status) else companyNameRef">
                            <a class="text-primary underline cursor-pointer" (click)="navigateToSupplierMaintenance(summary.value.vendorCode)">
                                {{ summary.value.supplier?.companyName }}
                            </a>
                        </ng-container>
                        <ng-template #companyNameRef>
                            {{ summary.value.supplier?.companyName }}
                        </ng-template>
                    </td>
                    <td>
                        {{summary.value.totalAmountWithTaxBeforNego ? (summary.value.totalAmountWithTaxBeforNego | number: ".2-2") : '0.00'}}
                    </td>
                    <td>
                        {{summary.value.totalAmountWithTaxAfterNego ? (summary.value.totalAmountWithTaxAfterNego | number: ".2-2") : '0.00'}}
                    </td>
                    <td>
                        {{
                            (summary.value.totalAmountWithTaxAfterNego <= 0)
                            ? '0.00'
                            : ((summary.value.totalAmountWithTaxAfterNego - summary.value.totalAmountWithTaxBeforNego) | number: ".2-2")
                        }}
                    </td>
                </tr>
            </table>
        </div>
    </div>

    <mat-label class="mt-6 px-6 py-3 text-gray-900 font-bold border block border-b-0">
        Selection Criteria
    </mat-label>
    <div class="p-6 border">
        <div class="table-box1 overflow-x-auto border">
            <table>
                <ng-container formGroupName="proposalSelectionCriteria">
                    <tr>
                        <th class="col1 min-w-[49px]">No</th>
                        <th class="col2">Description</th>
                        <th>Target</th>
                        <ng-container formArrayName="suppliers"
                            *ngFor="
                                let creds of form
                                    .get('proposalSelectionCriteria')
                                    .get('suppliers')?.controls;
                                let i = index
                            ">
                            <ng-container [formGroupName]="i">
                                <th>
                                    {{ creds.controls.name.value }}
                                </th>
                            </ng-container>
                        </ng-container>
                    </tr>

                    <tr>
                        <th class="col1">1</th>
                        <th class="col2">Quality/Specification</th>
                        <td class="text-area-container">
                            <mat-form-field class="fuse-mat-dense boq-item-text-area">
                                <textarea matInput class="small-text-area" rows="1" formControlName="qualitySpecificationTarget"></textarea>
                            </mat-form-field>
                        </td>
                        <ng-container formArrayName="suppliers"
                            *ngFor="
                                let creds of form
                                    .get('proposalSelectionCriteria')
                                    .get('suppliers')?.controls;
                                let i = index
                            ">
                            <ng-container [formGroupName]="i">
                                <td [ngClass]="{ yes: creds.disabled && creds.controls.status.value !== supplierStatusEnum.QUALIFIED}">
                                    <app-dropdown [options]="quality"
                                        [dropdownType]="
                                            dropdownTypeEnum.SIMPLE
                                        "
                                        optionViewKey="key"
                                        [outputPattern]="['value']"
                                        formControlName="qualitySpecification"></app-dropdown>
                                </td>
                            </ng-container>
                        </ng-container>
                    </tr>

                    <tr>
                        <th class="col1">2</th>
                        <th class="col2">Lead Time in Day(s)</th>
                        <td class="text-area-container">
                            <mat-form-field class="fuse-mat-dense boq-item-text-area">
                                <textarea matInput class="small-text-area" rows="1" formControlName="leadTimeTarget"></textarea>
                            </mat-form-field>
                        </td>
                        <ng-container formArrayName="suppliers"
                            *ngFor="
                                let creds of form
                                    .get('proposalSelectionCriteria')
                                    .get('suppliers')?.controls;
                                let i = index
                            ">
                            <ng-container [formGroupName]="i">
                                <td class="text-area-container" [ngClass]="{ yes: creds.disabled && creds.controls.status.value !== supplierStatusEnum.QUALIFIED}">
                                    <mat-form-field class="fuse-mat-dense boq-item-text-area">
                                        <textarea matInput class="small-text-area" rows="1" formControlName="leadTime"></textarea>
                                    </mat-form-field>
                                </td>
                            </ng-container>
                        </ng-container>
                    </tr>

                    <tr>
                        <th class="col1">3</th>
                        <th class="col2">Cost</th>
                        <td class="text-area-container">
                            <mat-form-field class="fuse-mat-dense boq-item-text-area">
                                <textarea matInput class="small-text-area" rows="1" formControlName="costTarget"></textarea>
                            </mat-form-field>
                        </td>
                        <ng-container formArrayName="suppliers"
                            *ngFor="
                                let creds of form
                                    .get('proposalSelectionCriteria')
                                    .get('suppliers')?.controls;
                                let i = index
                            ">
                            <ng-container [formGroupName]="i">
                                <td class="text-area-container" [ngClass]="{ yes: creds.disabled && creds.controls.status.value !== supplierStatusEnum.QUALIFIED}">
                                    <mat-form-field class="fuse-mat-dense boq-item-text-area">
                                        <textarea matInput class="small-text-area" rows="1" formControlName="cost"></textarea>
                                    </mat-form-field>
                                </td>
                            </ng-container>
                        </ng-container>
                    </tr>

                    <tr>
                        <th class="col1">4</th>
                        <th class="col2">Warranty</th>
                        <td class="text-area-container">
                            <mat-form-field class="fuse-mat-dense boq-item-text-area">
                                <textarea matInput class="small-text-area" rows="1" formControlName="warrantyTarget"></textarea>
                            </mat-form-field>
                        </td>
                        <ng-container formArrayName="suppliers"
                            *ngFor="
                                let creds of form
                                    .get('proposalSelectionCriteria')
                                    .get('suppliers')?.controls;
                                let i = index
                            ">
                            <ng-container [formGroupName]="i">
                                <td class="text-area-container" [ngClass]="{ yes: creds.disabled && creds.controls.status.value !== supplierStatusEnum.QUALIFIED}">
                                    <mat-form-field class="fuse-mat-dense boq-item-text-area">
                                        <textarea matInput class="small-text-area" rows="1" formControlName="warranty"></textarea>
                                    </mat-form-field>
                                </td>
                            </ng-container>
                        </ng-container>
                    </tr>
                </ng-container>
            </table>
        </div>
    </div>

    <mat-label class="mt-6 px-6 py-3 text-gray-900 font-bold border block border-b-0">
        Evaluation Criteria
    </mat-label>
    <div class="p-6 border mb-6">
        <div class="table-box1 overflow-x-auto border">
            <table>
                <tr>
                    <th class="border-0 min-w-[160px] col1">Supplier Name</th>
                    <ng-container formArrayName="proposalsEvaluationStatus"
                        *ngFor="
                            let creds of form.get(
                                'proposalsEvaluationStatus'
                            )?.controls;
                            let i = index
                        ">
                        <ng-container [formGroupName]="i">
                            <ng-container>
                                <th [ngClass]="{ yes: creds.disabled && creds.controls.status.value !== supplierStatusEnum.QUALIFIED}">
                                    {{ creds.controls.name.value }}
                                </th>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </tr>
                <tr>
                    <th class="border-0 col1">Supplier Evaluation</th>
                    <ng-container formArrayName="proposalsEvaluationStatus"
                        *ngFor="
                            let creds of form.get(
                                'proposalsEvaluationStatus'
                            )?.controls;
                            let i = index
                        ">
                        <ng-container [formGroupName]="i">
                            <ng-container>
                                <td class="border-0"
                                    [ngClass]="{ yes: creds.disabled && creds.controls.status.value !== supplierStatusEnum.QUALIFIED}">
                                    <app-dropdown [options]="evalution"
                                        [dropdownType]="
                                            dropdownTypeEnum.SIMPLE
                                        "
                                        optionViewKey="key"
                                        [outputPattern]="['value']"
                                        formControlName="supplierEvaluation"></app-dropdown>
                                </td>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </tr>
                <tr>
                    <th class="border-0 col1">Remark</th>
                    <ng-container formArrayName="proposalsEvaluationStatus"
                        *ngFor="
                            let creds of form.get(
                                'proposalsEvaluationStatus'
                            )?.controls;
                            let i = index
                        ">
                        <ng-container [formGroupName]="i">
                            <ng-container>
                                <td class="text-area-container" [ngClass]="{ yes: creds.disabled && creds.controls.status.value !== supplierStatusEnum.QUALIFIED}">
                                    <mat-form-field class="fuse-mat-dense boq-item-text-area">
                                        <textarea matInput class="small-text-area" rows="1" formControlName="remark"></textarea>
                                    </mat-form-field>
                                </td>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </tr>
                <tr>
                    <th class="border-0 col1">Tax Exemption Number</th>
                    <ng-container formArrayName="proposalsEvaluationStatus"
                        *ngFor="
                            let creds of form.get(
                                'proposalsEvaluationStatus'
                            )?.controls;
                            let i = index
                        ">
                        <ng-container [formGroupName]="i">
                            <ng-container>
                                <td class="text-area-container" [ngClass]="{ yes: creds.disabled && creds.controls.status.value !== supplierStatusEnum.QUALIFIED}">
                                    <mat-form-field class="fuse-mat-dense boq-item-text-area">
                                        <textarea matInput class="small-text-area" rows="1" formControlName="taxExemtionNo"></textarea>
                                    </mat-form-field>
                                </td>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </tr>
            </table>
        </div>
    </div>

    <app-text-area rows="4"
        placeholder="Recommendation"
        fieldLabel="Recommendation:"
        formControlName="recommendation"
        class="md:w-4/12 w-full inline-block mr-5">
    </app-text-area>

    <app-text-area rows="4"
        placeholder="Payment Schedule"
        fieldLabel="Payment Schedule:"
        formControlName="paymentSchedule"
        class="md:w-4/12 w-full inline-block">
    </app-text-area>

    <mat-label class="mt-5 px-6 py-3 text-gray-900 font-bold border block border-b-0">
        File Upload
    </mat-label>
    <div class="p-6 border">
        <mat-form-field class="fuse-mat-dense for-upload-box flex-auto w-full md:w-4/12 mt-1"
            [floatLabel]="'always'">
            <mat-label>Upload Files</mat-label>
            <input matInput
                placeholder="Browse File"
                [readonly]="true"
                [disabled]="isFormDisable()" />
            <button type="button"
                mat-icon-button
                [disabled]="isFormDisable()">
                <img src="../../../../../../assets/icons/upload.svg"
                    alt="" />
            </button>
            <input class="file-btn"
                autocomplete="off"
                type="file"
                [multiple]="true"
                (change)="uploadFileHandler($event)"
                accept="application/pdf,
                    application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
                    application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document,
                    application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.slideshow,
                    application/vnd.openxmlformats-officedocument.presentationml.presentation,
                    image/png, image/jpeg, image/jpg, video/mp4, video/avi"
                [disabled]="isFormDisable()" />
        </mat-form-field>

        <div class="table-box1 pb-3"
            *ngIf="form.get('sourcingProposalDoc').value.length">
            <table mat-table
                [dataSource]="form.get('sourcingProposalDoc').value">
                <ng-container matColumnDef="filename">
                    <th mat-header-cell
                        *matHeaderCellDef>File Name</th>
                    <td mat-cell
                        *matCellDef="let element">
                        {{ element.filename }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="createdDate">
                    <th mat-header-cell
                        *matHeaderCellDef>Upload Date</th>
                    <td mat-cell
                        *matCellDef="let element">
                        {{element.createdAt | date : "dd/MM/yyyy hh:mm a"}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="actions">
                    <th mat-header-cell
                        *matHeaderCellDef>Actions</th>
                    <td mat-cell
                        *matCellDef="let element; let i = index">
                        <button mat-icon-button (click)="downloadDoc(element)"
                            type="button">
                            <mat-icon>download</mat-icon>
                        </button>
                        <button mat-icon-button
                            type="button"
                            (click)="removeDoc(i, element)"
                            [disabled]="isFormDisable()">
                            <mat-icon aria-hidden="false"
                                aria-label="Example home icon">delete</mat-icon>
                        </button>
                    </td>
                </ng-container>

                <tr mat-header-row
                    *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row
                    *matRowDef="let row; columns: displayedColumns"></tr>
                <tr class="mat-row"
                    *matNoDataRow>
                    <td class="mat-cell"
                        colspan="5">No document uploaded</td>
                </tr>
            </table>
        </div>

        <p class="text-gray-500 mt-6">Note:</p>
        <ul class="md:w-5/12">
            <li class="text-gray-500">Max allowed file size is 50 MB</li>
            <li class="text-gray-500">
                Allowed file extensions:[pdf, doc, docx, xlsx, jpeg, rar, zip,
                png, ppt, xls, pptx, ai, eml].
            </li>
        </ul>
    </div>

    <div class="flex items-center pt-6"
        *ngIf="showDraftAndApprovalBtn">
        <button type="button"
            mat-raised-button
            class="bg-[#D9D9D9] rounded"
            (click)="saveDraftOrApproval(sourcingPropsalFormAction.DRAFT)">
            Save Draft
        </button>
        <button class="mat-primary ml-4 rounded"
            mat-raised-button
            (click)="saveDraftOrApproval(sourcingPropsalFormAction.SUBMIT)">
            Submit for Approval
        </button>
    </div>
</form>

<form [formGroup]="approvalForm"
    *ngIf="showApprovalForm">
    <div class="md:flex md:flex-row my-6 mx-0">
        <div class="md:w-8/12 md:pr-5 w-full">
            <mat-form-field class="fuse-mat-dense w-9/12">
                <mat-label>Remark</mat-label>
                <textarea matInput
                    placeholder=""
                    autocomplete="off"
                    rows="3"
                    formControlName="remark"></textarea>
            </mat-form-field>
            <mat-error *ngIf="showRemarkError">Remark is required</mat-error>

            <div class="md:flex items-center pt-3">
                <button class="mat-primary rounded"
                    mat-raised-button
                    (click)="
                        approveRejectAction(proposalLevelStatusEnum.APPROVED)
                    ">
                    <mat-icon>done</mat-icon> Approve
                </button>
                <button type="button"
                    mat-raised-button
                    class="bg-[#D9D9D9] rounded ml-3"
                    (click)="
                        approveRejectAction(proposalLevelStatusEnum.REJECTED)
                    ">
                    <mat-icon class="text-lg">close</mat-icon> Reject
                </button>
            </div>
        </div>
    </div>
</form>


<mat-expansion-panel class="shadow-none my-6 border rounded-none"
    expanded
    *ngIf="proposalApprovalLevels?.length > 0">
    <mat-expansion-panel-header class="text-gray-900 font-bold text-base">
        Sourcing Proposal Approval
    </mat-expansion-panel-header>
    <div class="m-6">
        <ng-container *ngFor="let item of proposalApprovalLevels">
            <mat-label class="font-medium text-gray-600 w-5/12 inline-flex items-center">
                Level {{ item.levelSequence }}
                <span class="text-green-600 ml-2 inline-flex items-center"
                    *ngIf="
                        item.isCurrentActiveLevel &&
                        item.levelStatus === proposalLevelStatusEnum.PENDING
                    ">
                    <mat-icon class="text-xl text-green-600">west</mat-icon>[Active]
                </span>
                <span class="text-red-600 ml-2 inline-flex items-center"
                    *ngIf="item.levelStatus === proposalLevelStatusEnum.ESCALATED">
                    [Escalated]
                </span>
            </mat-label>
            <p class="text-gray-600 flex items-center mb-3">
                <mat-icon class="text-green-400"
                    *ngIf="
                        item.levelStatus === proposalLevelStatusEnum.APPROVED
                    ">
                    done
                </mat-icon>
                <mat-icon class="text-primary mr-2">person</mat-icon>{{ item.userDetails.name }}
            </p>
        </ng-container>
    </div>
</mat-expansion-panel>

<div class="flex items-center justify-start mt-6" *ngIf="showEscalateBtn">
    <button class="mat-primary rounded" mat-raised-button
        (click)="escalateOnAction()">
        Escalate
    </button>
</div>

<mat-expansion-panel class="shadow-none mt-6 mb-0 border rounded-none" expanded>
    <mat-expansion-panel-header>
        <mat-panel-title class="text-gray-900 font-bold text-base">
            Sourcing Proposal Approval Comments
        </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="table-box1 border m-6 overflow-x-auto">
        <table class="w-full">
            <ng-container *ngIf="!this.sourcingEvalution?.sourcingProposalRoute?.histories
                || this.sourcingEvalution?.sourcingProposalRoute?.histories.length === 0; else proposalApprovalCommentsRef">
                <tr class="h-[40px]">
                    <td class="md:w-4/12 font-medium" colspan="2">
                        No data found
                    </td>
                </tr>
            </ng-container>
            <ng-template #proposalApprovalCommentsRef>
                <ng-container *ngFor="let proposalApprovalHistory of this.sourcingEvalution?.sourcingProposalRoute?.histories">
                    <tr class="h-[50px]">
                        <th class="md:w-4/12 font-medium">
                            <div class="flex flex-col">
                                <div class="flex flex-row ml-2">
                                    <mat-icon class="text-green-500 text-2xl mr-2"
                                        *ngIf="proposalApprovalHistory.action === 'Approved'">
                                        check_circle</mat-icon>
                                    <mat-icon class="text-red-500 text-2xl mr-2"
                                        *ngIf="!(proposalApprovalHistory.action === 'Approved')
                                                && proposalApprovalHistory.comment !== 'Approval Inactive Due To Time Out'">
                                        cancel</mat-icon>
                                    <mat-icon class="text-yellow-500 text-2xl mr-2"
                                        *ngIf="!(proposalApprovalHistory.action === 'Approved')
                                                && proposalApprovalHistory.comment === 'Approval Inactive Due To Time Out'">
                                        access_time_filled</mat-icon>
                                    <p>
                                        {{ proposalApprovalHistory.userDetails.name }}
                                    </p>
                                </div>
                                <pre class="ml-4 pl-6 text-left">
                                    {{ proposalApprovalHistory.comment }}
                                </pre>
                            </div>
                        </th>
                        <td class="w-6/12">
                            {{proposalApprovalHistory.createdAt | date: "EE dd/MM/yyyy hh:mm a"}}
                        </td>
                    </tr>
                </ng-container>
            </ng-template>
        </table>
    </div>
</mat-expansion-panel>
